import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import medicalFiles from "./medicalFile.module";
import filesModule from "./files.module";
import settingsModule from "./settings.module";
import commentsModule from "./comments.module";
import videoModule from "./video.module";
import devicesModule from "./devices.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    medicalFiles,
    filesModule,
    settingsModule,
    commentsModule,
    videoModule,
    devicesModule
  }
});
