import ApiService from '@/services/api.service';
import CryptoService from '@/services/crypto.service';

// action types
export const FETCH_MEDICAL_HISTORY_TYPES = 'fetchMedicalHistoryTypes';
export const FETCH_VITAL_TYPES = 'fetchVitalTypes';
export const FETCH_PRESCRIPTION_MEDS_FIELDS = 'fetchPrescriptionMedsFields';

// getters
export const GET_MEDICAL_HISTORY_TYPES = 'getMedicalHistoryTypes';
export const GET_VITAL_TYPES = 'getVitalTypes';
export const GET_TRANSLATED_VITAL_TYPES = 'getTranslatedVitalTypes';
export const GET_LANG = 'getLang';
export const GET_DIAGNOSIS_LEVELS = 'getDiagnosisLevels';
export const GET_PRESCRIPTION_MEDS_FIELDS = 'getPrescriptionMedsFields';

const state = {
  medicalHistoryTypes: [],
  vitalTypes: [],
  diagnosisLevels: [
    { name: 'Possible', color: 'red' },
    { name: 'Probable', color: 'orange' },
    { name: 'Almost certain', color: 'yellow' },
    { name: 'Certain', color: 'green' },
  ],
  lang: 'en',
  prescriptionMedicineFields: [],
};

const getters = {
  [GET_MEDICAL_HISTORY_TYPES](state) {
    return state.medicalHistoryTypes;
  },
  [GET_VITAL_TYPES](state) {
    return state.vitalTypes;
  },
  [GET_TRANSLATED_VITAL_TYPES](state) {
    const toReturn = state.vitalTypes.map((item) => {
      item.translation = item.translation.find((t) => t.lang === state.lang);
      return item;
    });
    return toReturn;
  },
  [GET_DIAGNOSIS_LEVELS](state) {
    return state.diagnosisLevels;
  },
  [GET_LANG](state) {
    return state.lang;
  },
  [GET_PRESCRIPTION_MEDS_FIELDS](state) {
    return state.prescriptionMedicineFields;
  },
};

const actions = {
  async [FETCH_MEDICAL_HISTORY_TYPES](context) {
    if (window.localStorage.getItem(process.env.VUE_APP_HISTORY_TYPE)) {
      const ls = window.localStorage.getItem(process.env.VUE_APP_HISTORY_TYPE);
      context.state.medicalHistoryTypes = JSON.parse(CryptoService.decrypt(ls));
      return context.state.medicalHistoryTypes;
    } else {
      const response = await ApiService.get(
        'items',
        `history_type?fields=*,translation.*`
      );
      const encrypted = CryptoService.encrypt(
        JSON.stringify(response.data.data)
      );
      window.localStorage.setItem(process.env.VUE_APP_HISTORY_TYPE, encrypted);
      context.state.medicalHistoryTypes = response.data.data;
      return context.state.medicalHistoryTypes;
    }
  },
  async [FETCH_VITAL_TYPES](context) {
    if (window.localStorage.getItem(process.env.VUE_APP_VITAL_TYPE)) {
      const ls = window.localStorage.getItem(process.env.VUE_APP_VITAL_TYPE);
      context.state.vitalTypes = JSON.parse(CryptoService.decrypt(ls));
      return context.state.vitalTypes;
    } else {
      const response = await ApiService.get(
        'items',
        `vital_types?fields=*,translation.*`
      );
      const encrypted = CryptoService.encrypt(
        JSON.stringify(response.data.data)
      );
      window.localStorage.setItem(process.env.VUE_APP_VITAL_TYPE, encrypted);
      context.state.vitalTypes = response.data.data;
      return context.state.vitalTypes;
    }
  },
  async [FETCH_PRESCRIPTION_MEDS_FIELDS](context) {
    const response = await ApiService.get('fields', `prescription_meds`);

    context.state.prescriptionMedicineFields = response.data.data;
    console.log('response.data.data', response.data.data);
    return context.state.prescriptionMedicineFields;
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
