import ApiService from '@/services/api.service';

// action types
export const FETCH_COMMENTS = 'fetchComments';
export const CREATE_COMMENT = 'createComment';

// getters
export const GET_COMMENTS = 'getComments';
export const SET_COMMENTS = 'setComments';

const state = {
  comments: [],
};

const getters = {
  [GET_COMMENTS](state) {
    return state.comments;
  },
};

const actions = {
  async [FETCH_COMMENTS](context, payload) {
    let url = `activity`;
    url += `?filter[collection][_eq]=${payload.collection}&filter[item][_eq]=${payload.item}&filter[action][_eq]=comment&sort=-id&fields[]=id&fields[]=action&fields[]=timestamp&fields[]=user.id&fields[]=user.email&fields[]=user.first_name&fields[]=user.last_name&fields[]=user.avatar.id&fields[]=revisions.id&fields[]=comment`;
    const response = await ApiService.get(url);

    context.commit(SET_COMMENTS, response.data.data);

    return response.data.data;
  },
  async [CREATE_COMMENT](context, payload) {
    let url = `activity/comment`;

    const response = await ApiService.post(url, payload);
    await context.dispatch(FETCH_COMMENTS, payload);
    return response.data.data;
  },
};

const mutations = {
  [SET_COMMENTS](state, payload) {
    state.comments = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
