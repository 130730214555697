import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify, {
    theme: {
      primary: '#0872cf',
  }});

export default new Vuetify({
});
