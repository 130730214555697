import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/services/jwt.service';
import router from '@/router';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (
            error.response.status == 401 &&
            router.currentRoute.fullPath !== '/login'
          ) {
            if (!window.location.origin.includes('login'))
              router.push({ name: 'login' });
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
        throw error;
      }
    );
    this.setHeader();
  },

  /**
   * Set the default HTTP request headers
   * 'Content-Type': 'multipart/form-data'
   * contentType="multipart/form-data"
   */
  setHeader() {
    const accessToken = JwtService.getAccessToken();
    if(!accessToken)
    {
      return;
    }
    Vue.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getAccessToken()}`;
  },

  removeHeader() {
    delete Vue.axios.defaults.headers.common['Authorization'];
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug) {
    const query = slug ? `${resource}/${slug}` : `${resource}`;
    return Vue.axios.get(query);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.patch(`${resource}/${slug}`, params);
  },

  async upload(data) {
    const response = await Vue.axios.post(`files`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      config : {
        onUploadProgress: progressEvent => console.log(progressEvent.loaded)
    }
    });

    return response.data.data;
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, items) {
    if (items.length === 1 || !Array.isArray(items)) {
      const payload = Array.isArray(items) ? items[0] : items;
      Vue.axios
        .delete(`${resource}/${payload}`)
        .catch((error) => {
          throw new Error(`[RWV] ApiService ${error}`);
        });
    } else {
      Vue.axios
        .delete(`${resource}`, { data: items })
        .catch((error) => {
          throw new Error(`[RWV] ApiService ${error}`);
        });
    }
  },
  /**
   * GET ICD code and descriptions
   * @param resource
   * @returns {*}
   */
  getICD(icd, diagnosticsname) {
    try {
      return Vue.axios.get(icd + '/' + diagnosticsname + '/', {
        headers: {
          'x-rapidapi-key':
            '3fd8f5ec84msh0224d43397266dep19f940jsn0faf3e5c89ee',
          'x-rapidapi-host': 'diagnostics-code-list.p.rapidapi.com',
        },
        baseURL: 'https://diagnostics-code-list.p.rapidapi.com/',
      });
    } catch (error) {
      return error;
    }
  },
  /**
   * GET user location currency
   * @param resource
   * @returns {*}
   */
  getLocationDetails() {
    try {
      return Vue.axios.get('', {
        params: { apikey: '873dbe322aea47f89dcf729dcc8f60e8' },
        headers: {
          'x-rapidapi-key':
            '3fd8f5ec84msh0224d43397266dep19f940jsn0faf3e5c89ee',
          'x-rapidapi-host':
            'find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com',
        },
        baseURL:
          'https://find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com/iplocation',
      });
    } catch (error) {
      return error;
    }
  },
};

export default ApiService;
