<template>
  <v-app>
    <router-view />
   
  </v-app>
</template>

<style lang="scss">
@import "assets/styles";
</style>

<script>
import {FETCH_MEDICAL_HISTORY_TYPES, FETCH_VITAL_TYPES} from '@/services/store/settings.module'
export default {
  name: 'App',

  /*components: {
    HelloWorld,
  },*/

  data: () => ({
    //
  }),

  created()
  {
    this.$store.dispatch(FETCH_MEDICAL_HISTORY_TYPES)
    this.$store.dispatch(FETCH_VITAL_TYPES)
  }
};
</script>
