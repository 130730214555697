import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from "@/services/store";
import ApiService from "@/services/api.service";
import CryptoService from '@/services/crypto.service';
import { VERIFY_AUTH } from "@/services/store/auth.module";

import * as dayjs from 'dayjs'

const customParseFormat = require('dayjs/plugin/customParseFormat')
const advancedFormat = require('dayjs/plugin/advancedFormat')
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(utc);
dayjs.extend(timezone);

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

Vue.config.productionTip = false

ApiService.init();



Vue.prototype.$day = dayjs;
Vue.prototype.$api = ApiService;
Vue.prototype.$crypto = CryptoService;



router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.

  Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
if (to.name !== "login" && !store.getters.isAuthenticated)
      next({ name: "login" });
    else next();
  });

  // reset config to initial state

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});


new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
