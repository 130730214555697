import { openDB } from 'idb';

/**
 * Service to call HTTP request via Axios
 */

const dbPromise = async () => {
  if (!('indexedDB' in window)) {
    throw new Error('Browser does not support IndexedDB');
  }
  return await openDB('doctory', 1, {
    upgrade(db) {
      // Create a store of objects
      const store = db.createObjectStore('files', {
        // The 'id' property of the object will be the key.
        keyPath: 'id',
      });
      store.createIndex('id', 'id');
    },
  });
};

const saveToStorage = async (table, payload) => {
  try {
    const db = await dbPromise();
    await db.add(table, payload);
  } catch (error) {
    return error;
  }
};

const updateStorageItem = async (table, payload) => {
  try {
    const db = await dbPromise();
    await db.put(table, payload);
  } catch (error) {
    console.log('error',error)
    return error;
  }
};

const checkStorage = async (table) => {
  try {
    const db = await dbPromise();
    return await db.getAllFromIndex(table, 'id');
  } catch (error) {
    console.error(error);
    return error;
  }
};

const clearStorage = async (table) => {
  try {
    const db = await dbPromise();
    return await db.clear(table);
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default { saveToStorage, checkStorage, updateStorageItem, clearStorage };
