import { BleClient } from '@capacitor-community/bluetooth-le';

// action types
export const CONNECT_BLT = 'connect_blt';
export const START_SCAN_DEVICE = 'start_scan_device';
export const STOP_SCAN_DEVICE = 'stop_scan_device';
export const REQUEST_DEVICE = 'request_device';
export const CONNECT_DEVICE = 'connect_device';
export const DISCONNECT_DEVICE = 'disconnect_device';

// getters
export const GET_BLE_INSTANCE = 'get_ble_instance';
export const GET_IS_CONNECTED = 'get_is_connected';
export const GET_IS_SCANNING = 'get_is_scanning';
export const GET_SCANNING_ATTEMPTS = 'get_scanning_attempts';
export const GET_MAX_SCANNING_ATTEMPTS = 'get_max_scanning_attempts';
export const GET_CONNECTING_ATTEMPTS = 'get_connecting_attempts';
export const GET_MAX_CONNECTING_ATTEMPTS = 'get_max_connecting_attempts';
export const GET_CONNECTED_DEVICE = 'get_connected_device';

//setters
export const SET_IS_CONNECTED = 'set_is_connected';
export const SET_IS_SCANNING = 'set_is_scanning';
export const SET_SCANNING_ATTEMPTS = 'set_scanning_attempts';
export const SET_CONNECTING_ATTEMPTS = 'set_connecting_attempts';
export const SET_CONNECTED_DEVICE = 'set_connected_device';



const state = {
  // A variable to hold a local audio track.
  bleInstance: null,
  isConnected: false,
  isScanning: false,
  scanningAttempts: 0,
  maxScanningAttempts: 5,
  connectingAttempts: 0,
  maxConnectingAttempts: 5,
  connectedDevice:null
};

const getters = {
  [GET_BLE_INSTANCE](state) {
    return state.bleInstance;
  },
  [GET_IS_CONNECTED](state) {
    return state.isConnected;
  },
  [GET_IS_SCANNING](state) {
    return state.isScanning;
  },
  [GET_SCANNING_ATTEMPTS](state) {
    return state.scanningAttempts;
  },
  [GET_MAX_SCANNING_ATTEMPTS](state) {
    return state.maxScanningAttempts;
  },
  [GET_CONNECTING_ATTEMPTS](state) {
    return state.connectingAttempts;
  },
  [GET_MAX_CONNECTING_ATTEMPTS](state) {
    return state.maxConnectingAttempts;
  }
};

const mutations = {
  [SET_CONNECTED_DEVICE](state, payload) {
    state.connectedDevice = payload;
  },
};

const actions = {
  async [CONNECT_BLT]() {
    await BleClient.initialize({ androidNeverForLocation: true });


  },

  async [CONNECT_DEVICE](context, payload) {
    const device = await BleClient.requestDevice({
      name: payload.name,
      optionalServices: payload.optionalServices
    });
    return device;
  },
  async [DISCONNECT_DEVICE](context, payload) {
    console.log('trying to disconnect',payload)
    await BleClient.disconnect(payload);
  },
  
};

export default {
  state,
  actions,
  getters,
  mutations
};
