
import cryptojs from 'crypto-js'

/**
 * Service to call HTTP request via Axios
 */
const CryptoService = {
  init() {
   
  },

  decrypt(text){
    if(!text)
    {
      return;
    }
    return cryptojs.AES.decrypt(
      text,
      process.env.VUE_APP_SECRET
    ).toString(cryptojs.enc.Utf8)
  },

  encrypt(text) {
    return cryptojs.AES.encrypt(
      text,
      process.env.VUE_APP_SECRET
    ).toString()
  }

  
};

export default CryptoService;
