import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
//import UserService from "../user.service";
import CryptoService from '@/services/crypto.service';

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const GET_USER = "getUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setToken";
export const SET_USER = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: [],
  user: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  [GET_USER](state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  async [LOGIN](context, credentials) {
    try
    {
      ApiService.removeHeader();
      const response  = await ApiService.post("auth/login", credentials);
      if (response.status === 200) {
        response.data.data.timeStamp = Date.now()
        const encrypted = CryptoService.encrypt(
          btoa(JSON.stringify(response.data.data))
        );
        context.commit(SET_AUTH, encrypted);
        ApiService.setHeader();
        const user = context.dispatch(GET_USER)

        context.commit(SET_USER, user);

        return true;
      }
      else if(response?.data?.error)
      {
        context.commit(SET_ERROR, response.data.error.message);
        return false;
      }
    }
    catch(e)
    {
      context.commit(SET_ERROR, e.response?.data?.errors.map(e => e.message));
      return false;
    }
      
       
    
  },
  // eslint-disable-next-line no-unused-vars
  async [GET_USER](context, payload) {
    const user = await ApiService.get(`users/me?fields=*`)
    context.commit(SET_USER, user.data.data);
    return user.data.data
  },

  signout({ commit }) {
    commit(PURGE_AUTH);
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },

  async [VERIFY_AUTH](context) {
    const encryptedToken = JwtService.getToken();
    const token = encryptedToken ? JwtService.parseJwt(encryptedToken) : null;
    const isValid = token ? token.timeStamp + token.expires > Date.now() : false;
    if (
      !token || !isValid
    ) {
      context.commit(PURGE_AUTH);
    }
    if(!context.state.user)
    {
      console.log('verifying auth', )
     const user = await context.dispatch(GET_USER)
     console.log('verifying auth',user )
     context.commit(SET_USER, user);
    }
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    return (state.errors = error);
  },
  [SET_AUTH](state, payload) {
    state.isAuthenticated = true;
    state.errors = [];
    JwtService.saveToken(payload);
  },
  [SET_USER](state, payload) {
    state.user = payload
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = [];
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
