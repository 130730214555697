const ID_TOKEN_KEY = "id_token";
import CryptoService from './crypto.service';


export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getAccessToken = () =>{
  const ls = window.localStorage.getItem(ID_TOKEN_KEY);
  const parsed = parseJwt(ls);
  return parsed?.access_token
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const parseJwt = token => {
  try{
    const jsonPayload = JSON.parse(atob(CryptoService.decrypt(token)))

  return jsonPayload;
  }
  catch(e)
  {
    destroyToken();
    return null;
  }
  
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  //localStorage.clear();
};

export default { getToken, saveToken, destroyToken, parseJwt,getAccessToken };
